@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #cee5eb;
  --secondary-light: #95a7b7;
  --secondary-dark: #062442;
  --secondary-grey: #4b5e6d;
  --nav-width: 150px;
  --mobile-nav-width: 50px;
}

.App {
  text-align: center;
  display: flex;
  font-family: 'Raleway', sans-serif !important;
  background-color: var(--secondary-dark);
  min-height: 100vh;
  max-width: 100vw;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-header {
  min-height: 100vh;
  min-width: calc(100vw - 175px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}


.nav {
  /*width: var(--nav-width) !important;*/
  font-family: 'Raleway', sans-serif;
  position: fixed;
}

.nav-link {
  text-decoration: none;
  width: fit-content;
  height: fit-content;
}

.nav-item {
  margin-bottom: 0;
  margin-top: 0;
}

.nav-item:hover {
  transform: scale(1.3);
}

.hamburger {
  height: 100px;
  width: 100px;
}

.hidden-color-mode-icon {
  display: none;
}

.logo {
  height: 75px;
  margin-left: 15px;
}

.tooltip {
  font-size: 16px !important;
}

.pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}